import React from "react"
import ErudexLogo from "../utils/icons/Erudex Logo -02.png"
import FacebookIcon from "../utils/icons/Facebook Icon.svg"
import TwitterIcon from "../utils/icons/Twitter Icon.svg"
import LinkedinIcon from "../utils/icons/LinkedIn Icon.svg"
const Footer = () => {
  return (
    <div className=" bg-text-blue  w-full ">
      <div className="container sm:max-w-lg  flex flex-col justify-center items-center">
        <div className=" w-40 h-12">
          <img src={ErudexLogo} alt="logo"></img>
        </div>

        <p className="text-sm text-color-light-blue mt-8">
          Download App For
          <a
            href="https://play.google.com/store/apps/details?id=com.erudex.eduapp&hl=en_IN"
            target="_blank"
            rel="noopener noreferrer"
            className="underline px-2"
          >
            Android
          </a>
          |
          <a
            href="https://apps.apple.com/us/app/erudex/id1490399497"
            target="_blank"
            rel="noopener noreferrer"
            className="underline pl-2"
          >
            iOS
          </a>
        </p>
        <div className="flex mt-8 mb-16 flex-row justify-between items-center">
          <a
            href="https://www.facebook.com/weareerudex"
            target="_blank"
            rel="noopener noreferrer"
            className="underline pr-2"
          >
            <img src={FacebookIcon} alt="FacebookIcon"></img>
          </a>
          <a
            href="https://twitter.com/weareerudex"
            target="_blank"
            rel="noopener noreferrer"
            className="underline px-2"
          >
            <img src={TwitterIcon} alt="TwitterIcon"></img>
          </a>
          <a
            href="https://www.linkedin.com/company/erudex-private-limited"
            target="_blank"
            rel="noopener noreferrer"
            className="underline pl-2"
          >
            <img src={LinkedinIcon} alt="LinkedinIcon"></img>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
