import React from "react";
import { connectAutoComplete } from "react-instantsearch-dom";
import Autosuggest from "react-autosuggest";
import SearchIcon from "../utils/icons/Search Icon.svg";
import { Link } from "gatsby";
import "../css/searchbox.css";
const AutoComplete = connectAutoComplete(
  ({ hits, currentRefinement, refine }) => {
    const { searchResults, setSearchResults } = React.useState(false);
    return (
      <div className="flex flex-row">
        <div className="w-11/12">
          <Autosuggest
            suggestions={hits}
            // multiSection={true}
            onSuggestionsFetchRequested={({ value }) => refine(value)}
            onSuggestionsClearRequested={() => refine("")}
            getSuggestionValue={(hit) => hit.title}
            renderSuggestion={(hit) => (
              <Link
                to={`/${hit.parent.sourceInstanceName}/${
                  hit.objectID.substring(1, 8) +
                  `-` +
                  hit.title.replace(/\s/g, "-")
                }`}
              >
                <div className="w-full hover:bg-pale-grey bg-pale-blue px-10 z-10 py-2 border">
                  <div
                    className=" text-light-blue text-base font-medium"
                    style={{ fontFamily: "poppins" }}
                  >
                    {hit.title}
                  </div>

                  {/* <div
                  className="text-text-blue text-sm font-light"
                  style={{ fontFamily: "poppins" }}
                >
                  {hit.description}
                </div> */}
                </div>
              </Link>
            )}
            inputProps={{
              placeholder: "Type in what you’re looking for",
              value: currentRefinement,
              onChange: () => {},
            }}
            //  renderSectionTitle={section => section.index}
            //getSectionSuggestions={section => section.hits}
          />
        </div>
        <button
          className=" bg-text-violet text-white  sm:px-8 sm:py-6  rounded-r-md px-4 py-6"
          onClick={() => setSearchResults(true)}
        >
          <img className="filter-cream" src={SearchIcon} alt="Logo" />
        </button>
      </div>
    );
  }
);

export default AutoComplete;
