import React from "react"
import LinkIcon from "../utils/icons/Pop-up Tab Icon.svg"
import ErudexLogo from "../utils/icons/Erudex Logo -01.png"
import algoliasearch from "algoliasearch/lite"
import { Configure, InstantSearch } from "react-instantsearch-dom"
import "../css/searchbox.css"
import "../css/global.css"
import AutoComplete from "./AutoComplete"
import { Link } from "gatsby"

const Header = ({ children }) => {
  const searchClient = algoliasearch(
    "P66AXCZXDT",
    "de0a9ce18911ac18e5d1c045ba261454"
  )
  return (
    <div className=" bg-color-light-blue w-full ">
      <div className="container md:max-w-4xl sm:max-w-sm md:px-8 lg:px-0 max-w-xs xs:px-4 sm:px-0 mx-auto">
        <div className="flex flex-col ">
          <div className="flex flex-row items-center mt-8 justify-between">
            <div className=" w-40 h-12">
              <Link to="/">
                <img src={ErudexLogo} alt="logo"></img>
              </Link>
            </div>
            <a
              href="https://www.erudex.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex flex-row items-center ">
                <img src={LinkIcon} alt="Logo" />
                <p className="text-xs font-normal  text-text-blue px-2 leading-relaxed tracking-wide capitalize">
                  Go to Erudex
                </p>
              </div>
            </a>
          </div>
          <p className=" w-3/4 sm:text-2xl text-base mt-8  text-text-blue">
            Find all the help you need to learn or teach online
          </p>

          <InstantSearch searchClient={searchClient} indexName="Articles">
            <div className="mt-8 mb-16">
              <AutoComplete />
            </div>
            <Configure hitsPerPage={5} />
            {/* <Hits hitComponent={AutoComplete} /> */}
          </InstantSearch>
          {/* <div className="h-12"></div> */}
        </div>
      </div>
    </div>
    // </div>
  )
}

export default Header
