import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import "../css/global.css";
const SubscribeForm = () => {
  const [email, setEmail] = React.useState("");
  const [result, setResult] = React.useState(null);
  const [valid, setValid] = React.useState(true);

  const handleChange = (event) => {
    if (validateEmail(event.target.value)) {
      setValid(true);
      setEmail(event.target.value);
    } else {
      setValid(false);
    }
    //this.setState({ email: event.target.value })
  };
  const validateEmail = (value) =>
    value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

  const _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email);
    // console.log(result.result)

    setResult(result.result);
  };

  return (
    <div className=" bg-color-light-blue w-full">
      <div className="container md:max-w-4xl sm:max-w-sm md:px-8 lg:px-0 max-w-xs xs:px-4 sm:px-0 mx-auto">
        {result === "success" ? (
          <div className=" mt-24 p-8 overflow-hidden flex flex-col  items-center justify-around">
            <p className="text-2xl text-green-600">
              Thank you for your Subscription ,
            </p>
            <p className=" text-lg text-green-600">You will recieve updates</p>
          </div>
        ) : result === "error" ? (
          <div className="mt-24 p-8 overflow-hidden flex flex-col  items-center justify-around">
            <p className="text-2xl text-red-600">
              Error Occured while Subscribing,
            </p>
            <p className=" text-lg text-red-600">
              Please Try Again by refreshing the page
            </p>
          </div>
        ) : (
          <>
            {/* <div className="sm:pl-32 "> */}
            <form onSubmit={_handleSubmit}>
              <div className="mt-24 py-8  overflow-hidden flex flex-col sm:flex-row bg-color-light-blue items-center justify-between">
                <input
                  className="w-full rounded-md sm:rounded-l-md sm:rounded-r-none text-sm py-4 h-16 mx-auto focus:outline-none overflow-hidden shadow-lg focus:shadow-xs hover:shadow-xs hover:placeholder-indigo-200  focus:placeholder-indigo-200 bg-white placeholder-opacity-100 placeholder-text-violet sm:px-8 px-2 text-text-violet leading-tight "
                  id="email"
                  type="email"
                  placeholder="Enter your email to Subscribe to our newsletter"
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  style={{ textOverflow: "clip" }}
                />

                <div className="py-8  sm:items-center ">
                  {valid ? (
                    <button className=" bg-btn-blue rounded-md sm:rounded-r-md sm:rounded-l-none h-16 ">
                      <span className=" text-red-btn-text  text-lg py-8 px-4">
                        Subscribe
                      </span>
                    </button>
                  ) : (
                    <button
                      className="bg-blue-200 rounded-md sm:rounded-r-md sm:rounded-l-none h-16"
                      disabled
                    >
                      <span className=" text-red-btn-text  text-lg py-8 px-4 ">
                        Subscribe
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </form>
            {/* </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default SubscribeForm;
