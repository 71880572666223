import React from "react"
import PropTypes from "prop-types"
import "../css/tailwind.css"
import SubscribeForm from "./SubscribeForm"
import Footer from "./Footer"
import Header from "./Header"
import SEO from "./SEO"
const Layout = ({ children }) => {
  return (
    <div style={{ fontFamily: "poppins" }}>
      <SEO />
      <Header />

      <main>{children}</main>
      <SubscribeForm />
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
